<template>
  <pessoa-box class="box-actions-ativo"
              @dragenter.native.prevent.stop="app = 'arquivos'"
              @dragover.native.prevent.stop="app = 'arquivos'">
    <pessoa-aba-header :active="app" @changeApp="changeApp" />
    <!---->

    <div class="cont-bodyAll">

      <component v-bind:is="currentTabComponent" :parse-props="parseProps" class="ativo"></component>
      <div class="itemAba"></div><!-- END Observações -->
      <div class="itemAba"></div><!-- END Atividade -->
      <div class="itemAba"></div><!-- END Sugerir horários -->

      <div class="itemAba">
        <!---->
      </div>

      <div class="itemAba"></div><!-- END E-mail -->
      <div class="itemAba"></div><!-- END Arquivos -->
    </div>
  </pessoa-box>
</template>

<script>
import Vue from 'vue'
import PessoaMixin from './mixin'
import PessoaBox from "@/components/pessoa/components/include/pessoa/Box"
import PessoaAbaHeader from "@/components/pessoa/components/include/pessoa/AbaHeader"
import {pessoaApps} from "@/components/pessoa/components/include/pessoa/apps/appList"


for (let app in pessoaApps) {
  Vue.component(pessoaApps[app].name, pessoaApps[app].component)
}
// Vue.component(pessoaApps.observacoes.name, pessoaApps.observacoes.component)
// Vue.component(pessoaApps.atividade.name, pessoaApps.atividade.component)

export default {
  name: "PessoaAbas",
  mixins: [PessoaMixin],
  components: {
    PessoaAbaHeader,
    PessoaBox
  },
  data () {
    return {
      app: pessoaApps.observacoes.name,
      parseProps: {
        pessoa: this.pessoa,
        cliente: this.pessoa,
        entidade: 'pessoa',
        entidadeId: this.pessoa.id
      }
    }
  },
  computed: {
    currentTabComponent: function () {
      return this.app
    }
  },
  methods: {
    changeApp (name) {
      console.log('Change app to ', name)
      this.app = name
    }
  }
}
</script>
