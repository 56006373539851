<template>
  <pessoa-box class="box-negocios">
    <div class="headerBox">
      <div class="left">
        <strong>Negócios</strong>
      </div>
      <div class="right">
        <u-btn disable><i class="fas fa-plus"></i></u-btn>
      </div>
    </div><!---->

    <div class="bodyBox">
      <p>Negócio em aberto <span>(0)</span></p>
      <div class="c-negocioAndamento">
        <p>Negócio <!--Maria--></p>

        <div class="show-infos">
          <div class="cont-left">
            <span><i class="fas fa-user"></i> R$ 0</span>
          </div>
          <div class="cont-right">
            <i class="fas fa-exclamation-triangle"></i>
          </div>
        </div><!-- END Infos -->

        <ul class="l-barAndamento">
          <li class="barAtivo"></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div><!-- END Status Andamento -->

      <div class="cont-actions">
        <u-btn disable no-caps>Ver todos os negócios</u-btn>
      </div>
    </div>
  </pessoa-box>
</template>

<script>
import PessoaMixin from './mixin'
import PessoaBox from "@/components/pessoa/components/include/pessoa/Box"

export default {
  name: "PessoaNegocios",
  mixins: [PessoaMixin],
  components: {
    // PessoaDetalheField,
    PessoaBox,
    // UTooltip
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
