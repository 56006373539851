<template>
  <div class="mod-pessoa" v-if="!loading">
    <pessoa-cadastro ref="cadastro" />
    <pessoa-header @editar="editar" :pessoa="pessoa" />
    <div class="cont-allBox">
      <div class="f-left">
        <pessoa-detalhes @updatePerson="(p) => pessoa = p" :pessoa="pessoa" /><!-- END Box -->

        <!--<pessoa-box class="box-contato box-organizacao">
          <div class="headerBox">
            <div class="left">
              <strong>Organização</strong>
            </div>
          </div>

          <div class="bodyBox">
            <p>Nenhum organização está ligada a essa pessoa.</p>
            <a href="">+ Ligar uma organização</a>
          </div>
        </pessoa-box>--><!-- END Box -->

        <pessoa-organizacao-detalhes :pessoa="pessoa" />

        <pessoa-negocios :pessoa="pessoa" /><!-- END Box -->

        <pessoa-visao-geral :pessoa="pessoa" /><!-- END Box -->

        <pessoa-seguidores :pessoa="pessoa" :me="me" /><!-- END Box -->

        <pessoa-processos :pessoa="pessoa" /><!-- END Box -->
      </div><!-- Flex Left -->

      <div class="f-right">
        <pessoa-abas :pessoa="pessoa" /><!-- END Box -->

        <pessoa-planejamento :pessoa="pessoa" /><!-- END Box -->

        <pessoa-historico :pessoa="pessoa" /><!-- END Box -->
      </div>
    </div>
  </div>
  <div class="flex flex-center h-full" v-else>
    <sl-loading />
  </div>
</template>

<script>
/**
 * @TODO: <RAPHAEL> Poder excluir uma nota
 * @TODO: <RAPHAEL> Poder fixar uma nota
 * @TODO: <V2> Poder excluir um log (Somente administrador)
 * @TODO: <V2> Mostrar log de Planejamento no histórico e torná-lo funcional
 */
import {find} from '@/domain/pessoa/services'
import SlLoading from "@/components/layout/components/Loading"
import PessoaHeader from "@/components/pessoa/components/include/pessoa/Header"
import PessoaDetalhes from "@/components/pessoa/components/include/pessoa/Detalhes"
import PessoaOrganizacaoDetalhes from "@/components/pessoa/components/include/pessoa/DetalhesOrganizacao"
import PessoaVisaoGeral from "@/components/pessoa/components/include/pessoa/VisaoGeral"
import PessoaSeguidores from "@/components/pessoa/components/include/pessoa/Seguidores"
import PessoaNegocios from "@/components/pessoa/components/include/pessoa/Negocios"
import PessoaProcessos from "@/components/pessoa/components/include/pessoa/Processos"
import PessoaAbas from "@/components/pessoa/components/include/pessoa/PessoaAbas"
import PessoaPlanejamento from "@/components/pessoa/components/include/pessoa/Planejamento"
import PessoaHistorico from "@/components/pessoa/components/include/pessoa/Historico"
import PessoaCadastro from '@/components/pessoa/components/window/Cadastro'

export default {
  provide: function () {
    return {
      appContainer: this
    }
  },
  name: "Pessoa",
  components: {
    PessoaHistorico,
    PessoaPlanejamento,
    PessoaAbas,
    PessoaProcessos,
    PessoaNegocios,
    PessoaSeguidores,
    PessoaVisaoGeral,
    PessoaOrganizacaoDetalhes,
    PessoaDetalhes,
    PessoaHeader,
    SlLoading,
    PessoaCadastro
  },
  data () {
    return {
      pessoa: null,
      loading: true
    }
  },
  mounted() {
    this.load()
  },
  computed: {
    me () {
      return this.$uloc.auth.session.user.person
    }
  },
  methods: {
    load(id = null) {
      if (!id) {
        id = this.$route.params.id
      }
      this.loading = true
      find(id)
          .then(({data}) => {
            this.loading = false
            this.pessoa = data
            if (document && document.title) {
              document.title = this.pessoa.name
            }
            console.log(data)
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    editar() {
      this.$refs.cadastro.show(this.pessoa.id)
    }
  }
}
</script>
