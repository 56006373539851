<template>
  <div>
    <div class="def-box box-full box-green" v-if="pessoa.tags && Array.isArray(pessoa.tags) && pessoa.tags.length">
      <span v-for="tag in pessoa.tags.slice(0, 4)" class="m-l-xs">{{ tag.name }}</span>
      <span class="plus" v-if="pessoa.tags.length > 4">+{{ pessoa.tags.length - 4 }}</span>
      <!--<i class="fas fa-tag"></i>-->
    </div>
    <u-btn icon="tag" title="Etiquetas" icon-type="fa" class="no-border bg-white" color="white" rounded flat dense>
      <u-popover disable-close-on-click-outside ref="popover" class="window-context-menu e-input-modern label-size-2 size1 no-border-radius" anchor="bottom left"
                 self="top left">
        <div class="flex row wrapper-sm">
          <div>
            <autocomplete-multiple load-on-focus :search="searchPersonTags" :parse-value="parseTagsValues"
                                   v-model="pessoa.tags" placeholder="Novas etiquetas"/>
          </div>
          <u-btn class="m-l-sm" color="positive" label="Salvar" :loading="salvandoTags" @click="salvarTags"/>
        </div>
      </u-popover>
    </u-btn>
  </div>
</template>

<script>
import PessoaMixin from './mixin'
import {UPopover} from 'uloc-vue'
import AutocompleteMultiple from "@/components/layout/components/AutocompleteMultiple"
import searchTags from "@/components/pessoa/helpers/searchTags"
import {updatePersonTags} from "@/domain/pessoa/services"

export default {
  mixins: [PessoaMixin],
  name: "PessoaTags",
  components: {AutocompleteMultiple, UPopover},
  data() {
    return {
      salvandoTags: false
    }
  },
  methods: {
    searchPersonTags(terms, done) {
      searchTags(terms)
          .then(response => {
            const extra = {
              label: `Adicionar "${terms}" como nova tag`,
              name: terms,
              value: terms,
              isNew: true
            }
            let result = response.data.result.map(p => {
              return {
                ...p,
                label: p.name,
                value: p.name,
                active: null
              }
            })
            result.push(extra)
            done(result)
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    parseTagsValues: function (tags) {
      return tags.map(v => {
        return v.name
      })
    },
    salvarTags() {
      this.salvandoTags = true
      updatePersonTags(this.pessoa)
          .then(response => {
            this.salvandoTags = false
            this.$uloc.notify({
              color: 'positive',
              message: `Tags atualizadas com sucesso.`,
              position: 'bottom-left',
            })
            this.$refs.popover.hide()
          })
          .catch(error => {
            this.salvandoTags = false
            this.alertApiError(error)
          })
    }
  }
}
</script>
