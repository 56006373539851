<template>
  <pessoa-box class="box-processos">
    <div class="headerBox">
      <div class="left">
        <strong>Processos ({{ partes ? partes.length : 0 }})</strong>
      </div>
    </div><!---->

    <div class="bodyBox">
      <ul v-for="parte in partes" :key="parte.id" class="l-processos">
        <li>
          <p><a @click="gotoProcesso(parte.processo)" class="text-black hover-underline"><strong>{{ parte.processo.numero }}</strong></a> como <span>{{ parte.tipo.nome }}</span></p>
          <span>Situação: {{ parte.active ? 'Ativo' : 'Inativo' }}</span>
        </li><!---->
      </ul><!-- END Lista -->
      <div v-if="!loading && (!partes || !partes.length)" class="m-b">
        Esta pessoa não faz parte de nenhum processo
      </div>
      <div class="m-b flex items-center" v-if="loading">
        <sl-loading content="" class="size-18 m-r-sm"/>
        Buscando partes relacionadas
      </div>
    </div>
  </pessoa-box>
</template>

<script>
import PessoaMixin from './mixin'
import PessoaBox from "@/components/pessoa/components/include/pessoa/Box"
import {listProcessosRelacionados} from "@/domain/pessoa/services"
import SlLoading from "@/components/layout/components/Loading"
import globalProcessoMixin from "@/components/processos/globalProcessoMixin"

export default {
  name: "PessoaProcessos",
  mixins: [PessoaMixin, globalProcessoMixin],
  components: {
    SlLoading,
    // PessoaDetalheField,
    PessoaBox,
    // UTooltip
  },
  data() {
    return {
      expand: false,
      loading: false,
      partes: null
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      listProcessosRelacionados(this.pessoa)
          .then(response => {
            this.partes = response.data // @TODO: Limit?
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    }
  }
}
</script>
