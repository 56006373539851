import AppContatos from "@/components/pessoa/components/include/pessoa/apps/Contatos"
import AppObservacoes from "@/components/pessoa/components/include/pessoa/apps/Observacoes"
import AppAtividade from "@/components/pessoa/components/include/pessoa/apps/Atividade"
import Ligar from "@/components/pessoa/components/include/pessoa/apps/Ligar"
import Arquivos from "@/components/pessoa/components/include/pessoa/apps/Arquivos"
import Comunicacao from "@/components/pessoa/components/include/pessoa/apps/Comunicacao"

export const pessoaApps = {
    observacoes: {
        name: 'observacoes',
        component: AppObservacoes,
        label: 'Observações',
        icon: 'far fa-list-alt'
    },
    contato: {
        name: 'contato',
        component: AppContatos,
        label: 'Contatos',
        icon: 'far fa-address-book'
    },
    atividade: {
        name: 'atividade',
        component: AppAtividade,
        label: 'Tarefas',
        icon: 'far fa-tasks'
    },
    ligar: {
        name: 'ligar',
        component: Ligar,
        label: 'Ligar',
        icon: 'fas fa-phone fa-rotate-90'
    },
    email: {
        name: 'email',
        component: Comunicacao,
        label: 'Comunicação',
        icon: 'far fa-envelope'
    },
    arquivos: {
        name: 'arquivos',
        component: Arquivos,
        label: 'Arquivos',
        icon: 'far fa-paperclip'
    }
}
