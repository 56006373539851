export function getOwner(person) {
    if (!person.owners) {
        return null
    }
    let owner = null
    person.owners.map(o => {
        if (o.enabled) {
            owner = o.owner
        }
    })
    return owner
}
