<template>
  <pessoa-box class="box-detalhes box-seguidores">
    <div class="headerBox">
      <div class="left">
        <strong>Seguidores ({{totalSeguidores}})</strong>
      </div>
      <div class="right">
<!--        <button><i class="fas fa-plus"></i></button>-->
      </div>
    </div><!---->

    <div class="bodyBox">
      <ul class="l-seguidores">
        <li v-for="seguidor in seguidores" :key="seguidor.id" class="m-b-xs">
          <a @click="gotoPerson(seguidor.pessoaSeguindo)"> <i class="fas fa-user"/> {{ seguidor.pessoaSeguindo.name }}</a>
        </li><!---->
        <li v-if="!seguidores || !seguidores.length">
          Ninguém está seguindo esta pessoa ainda.
        </li>
      </ul><!-- END Lista -->

      <div class="cont-actions">
<!--        <button>Ver tudo</button>-->
        <u-btn v-if="isSeguindo" @click="naoSeguir()" class="min-icon" text-color="primary" :loading="loading" no-caps icon="bell" icon-type="fa"
               label="Parar de seguir"></u-btn>
        <u-btn v-else @click="seguir()" class="min-icon" no-caps icon="bell" icon-type="fa" :loading="loading" label="Seguir"></u-btn>
      </div><!-- END Actions -->
    </div>

    <div class="footerBox m-t-n-xs m-b-n-sm no-border">
      <div class="p">
        <p>Ao seguir uma pessoa, o sistema irá notificar o seguidor tudo que acontece com esta pessoa, ações, modificações, atividades e outros.</p>
      </div>
    </div>
  </pessoa-box>
</template>

<script>
import PessoaMixin from './mixin'
import PessoaBox from "@/components/pessoa/components/include/pessoa/Box"
import {UTooltip} from "uloc-vue"
import PessoaDetalheField from "@/components/pessoa/components/include/pessoa/DetalheField"
import {listSeguidores, naoSeguirPessoa, seguirPessoa} from "@/domain/pessoa/services"

export default {
  name: "PessoaSeguidores",
  mixins: [PessoaMixin],
  components: {
    // PessoaDetalheField,
    PessoaBox,
    // UTooltip
  },
  props: ['me'],
  data() {
    return {
      seguidores: null,
      loading: false
    }
  },
  mounted() {
    this.listarSeguidores()
  },
  computed: {
    isSeguindo() {
      if (!this.seguidores || !this.seguidores.length) {
        return false
      }
      let find = this.seguidores.find(s => s.pessoaSeguindo.id === Number(this.me))
      return find
    },
    totalSeguidores () {
      if (!this.seguidores || !this.seguidores.length) {
        return 0
      }
      return this.seguidores.length
    }
  },
  methods: {
    listarSeguidores() {
      this.loading = true
      listSeguidores(this.pessoa)
          .then(response => {
            this.loading = false
            this.seguidores = response.data
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    seguir() {
      this.loading = true
      seguirPessoa(this.pessoa)
          .then(({data}) => {
            this.loading = false
            this.seguidores = data
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    naoSeguir() {
      this.loading = true
      naoSeguirPessoa(this.pessoa)
          .then(({data}) => {
            this.loading = false
            this.seguidores = data
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    }
  }
}
</script>
