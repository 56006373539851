<template>
  <li class="with-box note">
    <i class="box-icon material-icons">description</i>
    <div class="with-box-content ">
      <div v-if="h.context.type === 1"> <!-- Realizada -->
        <div v-if="h.context.status === 102" class="text-negative">Tentativa falha de ligar para {{h.context.number}}</div>
        <div v-else>Ligação Realizada para {{h.context.number}}</div>
      </div>
      <div v-else> <!-- Recebida -->
        Ligação Recebida
      </div>
      <flow-info :h="h"/>
      <div class="font-12 m-t-xs m-b-sm" v-if="h.context.time">A chamada durou {{formattedTime(h.context.time)}}</div>
      <div v-html="h.context.notes"></div>
      <div v-if="h.context.person" class="m-t font-thin font-12">Contato realizado com <a @click="gotoPerson(h.context.person)">{{ h.context.person.name }}</a></div>
    </div>
  </li>
</template>

<script>
import flowMixin from "@/components/pessoa/components/include/historico/flowMixin"
import FlowInfo from "@/components/pessoa/components/include/historico/FlowInfo"
import {format, addSeconds} from 'date-fns'

export default {
  name: "FlowCall",
  components: {FlowInfo},
  mixins: [flowMixin],
  data() {
    return {
      showCommentsTab: false,
      isCommenting: false,
      loading: false,
      comment: '',
      forceShowEditor: false,
      comments: null,
      commentsLoading: false,
      forceCommentsCount: 0
    }
  },
  computed: {
  },
  methods: {
    cancel() {
      this.$refs.editor.forceBlur()
      this.isCommenting = false
      this.showCommentsTab = false
      this.comment = ''
    },
    firstLettersName(name) {
      name = name.split(' ')
      if (Array.isArray(name) && name.length > 1) {
        return name[0].charAt(0) + name[1].charAt(0)
      }
      return String(name).substr(0, 2)
    },
    gotoPerson (p) {
      let router = this.$router || this.router
      let routeData = router.resolve({name: 'pessoa.show', params: {id: p.id}})
      window.open(routeData.href, '_blank')
    },
    formattedTime(seconds) {
      const helperDate = addSeconds(new Date(0), seconds);
      return format(helperDate, "mm'm'ss's'");
    }
  }
}
</script>
