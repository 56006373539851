<template>
  <pessoa-box class="box-detalhes">
    <div class="headerBox">
      <div class="left">
        <strong>Detalhes</strong>
      </div>
      <div class="right">
        <u-btn icon="pen" icon-type="fa" @click="editing = !editing" class="simple-icon"
               :class="{'text-green': editing}"/>
        <u-btn @click="expand = !expand" :icon="expand ? 'unfold_less' : 'unfold_more'" class="simple-icon c-arrows">
          <u-tooltip content-class="bg-indigo" :offset="[10, 10]">
            <span v-if="expand">Recolher</span>
            <span v-else>Expandir</span>
          </u-tooltip>
        </u-btn>
        <!--        <u-btn label="Personalizar campos" no-caps />-->
      </div>
    </div><!---->

    <div class="bodyBox" :class="{editing: editing}">
      <pessoa-detalhe-field label="Nome" :value="pessoa.name">
        <template v-if="editing" v-slot:right>
          <u-input hide-underline class="app-input full-width" v-model="pessoa.name"/>
        </template>
      </pessoa-detalhe-field>
      <pessoa-detalhe-field label="Tipo" :value="pessoa.type === 1 ? 'Pessoa' : 'Empresa'">
        <template v-if="editing" v-slot:right>
          <u-select hide-underline class="app-input" v-model="pessoa.type" :options="tiposPessoa"/>
        </template>
      </pessoa-detalhe-field>

      <div v-for="(tel, index) in pessoa.phoneNumbers" :key="'tel-' + index" class="infoCont"
           :class="{'aditional': index !== 0}">
        <p class="txtLeft" v-if="index === 0">Telefone</p>
        <p class="txtLeft" v-else>&nbsp;</p>
        <p class="txtRight" v-if="!editing">
          <a :href="'callto:' + tel.phoneNumber">{{ tel.phoneNumber }}</a>
          <span v-if="tel.purpose && tel.purpose.name">({{ tel.purpose.name }})</span>
        </p>
        <div class="txtRight flex content-between no-wrap e-input-modern size1 full-width" v-else>
          <u-input hide-underline class="app-input" style="flex: 1" v-model="tel.phoneNumber"/>
          <tipo-telefone-select class="m-l-xs" style="max-width: 100px" required no-label
                                v-model="tel.purpose" is-value-object/>
          <div v-if="pessoa.phoneNumbers.length > 1" class="flex items-center" style="max-width: 20px">
            <a class="m-l-xs" @click="removeTelefone(tel, true)">
              <u-icon name="trash" type="fa" color="grey-7"/>
            </a>
          </div>
        </div>
      </div><!-- Info Cont -->
      <div v-if="editing" class="infoCont m-t-n-sm">
        <p class="txtLeft"></p>
        <a @click="adicionarTelefone" class="font-bold">+ Adicionar telefone</a>
      </div>

      <div v-for="(email, index) in pessoa.emails" :key="'mail-' + index" class="infoCont"
           :class="{'aditional': index !== 0}">
        <p class="txtLeft">E-mail</p>
        <p class="txtRight" v-if="!editing">
          <a href="">{{ email.email }}</a>
          <span v-if="email.purpose && email.purpose.name">({{ email.purpose.name }})</span>
        </p>
        <div class="txtRight flex content-between no-wrap e-input-modern size1 full-width" v-else>
          <u-input hide-underline class="app-input" style="flex: 1" v-model="email.email"/>
          <tipo-email-select v-if="!updatingEmail" class="m-l-xs" style="max-width: 100px" required no-label
                             v-model="email.purpose" is-value-object/>
          <div v-if="pessoa.emails.length > 1" class="flex items-center" style="max-width: 20px">
            <a class="m-l-xs" @click="removeEmail(email, true)">
              <u-icon name="trash" type="fa" color="grey-7"/>
            </a>
          </div>
        </div>
      </div><!-- Info Cont -->
      <div v-if="editing" class="infoCont m-t-n-sm">
        <p class="txtLeft"></p>
        <a @click="adicionarEmail" class="font-bold">+ Adicionar e-mail</a>
      </div>

      <div v-if="expand">
        <div v-for="(v, index) in pessoa.addresses" :key="'address-' + v.id" class="infoCont"
             :class="{'aditional': index !== 0}">
          <p class="txtLeft">Endereço</p>
          <p class="txtRight">
            <a target="_blank"
               :href="'https://www.google.com.br/maps/search/'+getAddress(v)+'/@-16.730852,-43.8922283,1050m/data=!3m1!1e3'">{{
                getAddress(v)
              }}</a>
            <span v-if="v.purpose">({{ v.purpose.name }})</span>
          </p>
        </div><!-- Info Cont -->
        <div v-if="!pessoa.addresses || !pessoa.addresses.length" class="infoCont">
          <p class="txtLeft">Endereço</p>
          <p class="txtRight">
            <span>-</span>
          </p>
        </div><!-- Info Cont -->
        <pessoa-detalhe-field label="Documento">
          <div v-if="!editing">
            {{ $options.filters.formataCpfCnpj(pessoa.document) }}
            <button v-if="pessoa.document" class="simple-btn p-consulta-btn pos-abt m-t-n-xs m-l-sm">
              <i class="erp-icon cpf"></i>
              <u-tooltip content-class="bg-indigo" :offset="[1, 1]">
                Consultar documento nos órgãos de proteção ao crédito
              </u-tooltip>
            </button>
            <!--            <div class="m-t-sm">
                          <button v-if="pessoa.document" class="simple-btn p-consulta-btn"><i class="erp-icon cpf"></i> Consultar</button>
                        </div>-->
          </div>
          <template v-if="editing" v-slot:right>
            <u-input hide-underline class="app-input" v-model="pessoa.document"/>
          </template>
        </pessoa-detalhe-field>
        <pessoa-detalhe-field v-if="!isEmpresa" label="Nacionalidade" :value="pessoa.nationality">
          <template v-if="editing" v-slot:right>
            <u-input hide-underline class="app-input" v-model="pessoa.nationality"/>
          </template>
        </pessoa-detalhe-field>
        <pessoa-detalhe-field v-if="!isEmpresa" label="Gênero" :value="pessoa.gender === 1 ? 'Masculino' : 'Feminino'">
          <template v-if="editing" v-slot:right>
            <u-select hide-underline class="app-input" v-model="pessoa.gender" :options="[
                {label: 'Masculino', value: 1},
                {label: 'Feminino', value: 2},
            ]"/>
          </template>
        </pessoa-detalhe-field>
        <pessoa-detalhe-field v-if="pessoa.birthDate || editing" :label="isEmpresa ? 'Data Fundação' : 'Data Nascimento'"
                              :value="pessoa.birthDate ? $options.filters.formatDate(pessoa.birthDate.date, 'dd/MM/yyyy') : ''">
          <template v-if="editing" v-slot:right>
            <u-input hide-underline class="app-input" v-model="fake.birthDate" v-mask="'##/##/####'"/>
          </template>
        </pessoa-detalhe-field>
        <pessoa-detalhe-field v-for="(contato, index) in pessoa.contacts" :key="'contato-' + contato.id"
                              :label="contato.name">
          <div>{{ contato.value }} <span v-if="contato.purpose">({{ contato.purpose.name }})</span></div>
        </pessoa-detalhe-field>
      </div>

      <pessoa-detalhe-field v-if="editing">
        <template v-slot:right>
          <u-btn @click="salvar" label="Salvar alterações" :loading="loading" no-caps
                 class="btnContato btnForm no-border"/>
        </template>
      </pessoa-detalhe-field>
    </div><!---->

    <!--<div class="footerBox">
      <div class="p">
        <p>Informação de Dados de Contato Inteligentes <strong class="status-contato status-">Avançado</strong></p>
        <a href="">Montrar mais</a>
      </div>
    </div>--><!---->
  </pessoa-box>
</template>

<script>
import PessoaMixin from './mixin'
import PessoaBox from "@/components/pessoa/components/include/pessoa/Box"
import {UTooltip, UInput, USelect} from "uloc-vue"
import PessoaDetalheField from "@/components/pessoa/components/include/pessoa/DetalheField"
import {Tipo} from "@/domain/pessoa/helpers/Status"
import TipoTelefoneSelect from "@/components/pessoa/components/include/TipoTelefoneSelect"
import TipoEmailSelect from "@/components/pessoa/components/include/TipoEmailSelect"
import PersonMixin from "@/components/pessoa/components/include/PersonMixin"
import {updatePerson} from "@/domain/pessoa/services";

export default {
  name: "PessoaDetalhes",
  mixins: [PessoaMixin, PersonMixin],
  components: {TipoEmailSelect, TipoTelefoneSelect, PessoaDetalheField, PessoaBox, UTooltip, UInput, USelect},
  data() {
    return {
      expand: false,
      editing: false,
      loading: false,
      fake: {
        birthDate: null
      },
      updatingEmail: false
    }
  },
  mounted() {
    if (this.pessoa.birthDate && this.pessoa.birthDate.date) {
      this.fake.birthDate = this.$options.filters.formatDate(this.pessoa.birthDate.date, 'dd/MM/yyyy')
    }
  },
  computed: {
    tiposPessoa() {
      let tipos = []
      for (let tipo in Tipo) {
        tipos.push({
          label: Tipo[tipo].title,
          value: Number(tipo)
        })
      }
      return tipos
    },
    isEmpresa () {
      return this.pessoa.type == 2
    }
  },
  methods: {
    getAddress(v) {
      return [v.address, v.number, v.complement, v.district, v.state, v.zip, v.city, v.country].join(', ') // @TODO: <RAPHAEL> url encoded, endereço completo, escape para evitar js injection. Fazer também edição do endereço, separando os inputs corretamente pelos campos
    },
    salvar() {
      this.loading = true
      let pessoa = this.transformToSubmit(this.pessoa)
      pessoa.birthDate = this.fake.birthDate
      updatePerson(pessoa)
          .then(response => {
            this.$emit('updatePerson', response.data)
            this.loading = false
            this.$uloc.notify({
              color: 'positive',
              message: `Cadastro atualizado com sucesso!`,
              position: 'bottom-left',
            })
            this.editing = false
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    }
  }
}
</script>
