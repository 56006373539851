<template>
  <pessoa-box class="box-visao">
    <div class="headerBox">
      <div class="left">
        <strong>Visão Geral</strong>
      </div>
    </div><!---->

    <div class="bodyBox">
      <ul class="l-dados">
<!--        <li>
          <span>Inativo (dias)</span>
          <span>0</span>
        </li>&lt;!&ndash;&ndash;&gt;-->

        <li>
          <span>Criado em</span>
          <span>{{ pessoa.createdAt|formatDate }}</span>
        </li>
        <li v-if="pessoa.createdByUser">
          <span>Criado por</span>
          <span>{{ pessoa.createdByUser }}</span>
        </li>
        <li v-if="pessoa.dateModified">
          <span>Última modificação</span>
          <span>{{ pessoa.dateModified|formatDate }}</span>
        </li>
        <li v-if="pessoa.modifiedByUser">
          <span>Última modificação por</span>
          <span>{{ pessoa.modifiedByUser }}</span>
        </li>
      </ul><!-- END Lista -->
    </div>
  </pessoa-box>
</template>

<script>
import PessoaMixin from './mixin'
import PessoaBox from "@/components/pessoa/components/include/pessoa/Box"

export default {
  name: "PessoaVisaoGeral",
  mixins: [PessoaMixin],
  components: {
    // PessoaDetalheField,
    PessoaBox,
    // UTooltip
  },
  data () {
    return {
      expand: false
    }
  },
  methods: {
  }
}
</script>
