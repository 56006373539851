<template>
  <pessoa-box class="no-border box-actions-planejado">
    <div class="c-center">
      <h5>Planejamento</h5>
    </div>

    <p>Não há nenhuma atividade futura</p>
    <div class="c-center">
<!--      <a href="">+ Agendar uma atividade</a>-->
    </div>
  </pessoa-box>
</template>

<script>
import PessoaMixin from './mixin'
import PessoaBox from "@/components/pessoa/components/include/pessoa/Box"

export default {
  name: "PessoaPlanejamento",
  mixins: [PessoaMixin],
  components: {
    PessoaBox
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
