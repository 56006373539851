<template>
  <u-popover :anchor-click="false" disable-close-on-click-outside ref="popover"
             class="window-context-menu erp-window erp-window-modern v2 e-input-modern label-size-2 size1"
             anchor="bottom left"
             self="top left">
    <div class="u-window-content wrapper-sm">
      <div class="w-m-content">
        <div>
          <p class="font-12 no-margin no-select" style="color: #293e52">Transferir propriedade</p>
        </div>
        <div>
          <autocomplete :search="searchPerson" v-model="pessoa.owner" icon="user"/>
        </div>
      </div>
    </div>
    <div class="w-m-footer footer-modern small">
      <u-btn @click="$refs.popover.hide()" label="Cancelar" no-caps
             :color="'white'"
             text-color="grey-8"
             class="b-radius-3px btn-white m-r-sm"/>
      <u-btn :loading="loading" @click="salvarOwner" label="Salvar" no-caps
             :color="'green'"
             class="b-radius-3px"/>
    </div>
  </u-popover>
</template>

<script>
import {UPopover} from 'uloc-vue'
import Autocomplete from "@/components/layout/components/Autocomplete"
import {list, updatePersonOwner} from '@/domain/pessoa/services'
const searchPerson = function (terms) {
  let filtros = encodeURI(`&tipo=1&sortBy=name&descending=false&search=${terms}`)
  return list(50, 1, filtros)
}

export default {
  name: "PopoverOwner",
  props: ['pessoa'],
  components: {UPopover, Autocomplete},
  data() {
    return {
      loading: false
    }
  },
  methods: {
    toggle () {
      this.$refs.popover.toggle()
    },
    searchPerson(terms, done) {
      searchPerson(terms)
          .then(response => {
            const extra = {
              label: `Adicionar "${terms}" como nova pessoa`,
              value: terms,
              isNew: true
            }
            let result = response.data.result.map(p => {
              return {
                ...p,
                label: p.name,
                value: p.name,
                active: null
              }
            })
            // result.push(extra)
            done(result)
          }).catch(error => {
        this.alertApiError(error)
      })
    },
    salvarOwner() {
      this.loading = true
      updatePersonOwner(this.pessoa)
          .then(response => {
            this.loading = false
            this.$emit('updateOwner', response.data)
            this.pessoa.owners = response.data.owners
            this.$uloc.notify({
              color: 'positive',
              message: `Proprietário atualizado com sucesso.`,
              position: 'bottom-left',
            })
            this.$refs.popover.hide()
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    desvincular () {
      this.loading = true
      let person = JSON.parse(JSON.stringify(this.pessoa))
      person.owner = null
      updatePersonOwner(person)
          .then(response => {
            this.loading = false
            this.$emit('updateOwner', response.data)
            this.pessoa.owners = response.data.owners
            this.$uloc.notify({
              color: 'positive',
              message: `Proprietário removido.`,
              position: 'bottom-left',
            })
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    }
  }
}
</script>

<style scoped>

</style>