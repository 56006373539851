<template>
  <pessoa-box class="box-detalhes box-organizacao">
    <div class="headerBox">
      <div class="left">
        <strong>Organização</strong>
      </div>
      <div class="right">
        <u-btn v-if="pessoa.organization" icon="ellipsis-h" icon-type="fa" class="simple-icon">
          <u-tooltip content-class="bg-indigo" :offset="[5, 5]">
            Mais ações
          </u-tooltip>
          <u-popover class="window-context-menu menu-profile min" anchor="bottom left" self="top left" :offset="[0, 5]">
            <div>
              <ul v-if="!loading">
                <menu-options-item @click="ligarOrganizacao()" label="Alterar para outra organização" close  />
                <menu-options-item @click="desvincular()" label="Desvincular esta organização" close  />
              </ul>
              <ul v-else>
                <menu-options-item label="Alterar para outra organização" close >
                  <span class="menuLabel">
                    <div class="flex col-grow no-wrap justify-between">
                      <div class="flex items-center">
                        <sl-loading class="size-18" />
                      </div>
                    </div>
                  </span>
                </menu-options-item>
              </ul>
            </div>
          </u-popover>
        </u-btn>
      </div>
    </div><!---->

    <div v-if="pessoa.organization && !edit" class="bodyBox">
      <pessoa-detalhe-field label="">
        <div class="flex flex-center">
          <u-icon color="blue" name="business" /> <div @click="gotoPerson(pessoa.organization)" class="m-l-sm font-14 font-bold cursor-pointer hover-underline">{{pessoa.organization.name}}</div>
        </div>
      </pessoa-detalhe-field>
    </div><!---->
    <div v-else-if="!edit" class="bodyBox">
      <div v-if="!edit">
        <p>Nenhum organização está ligada a essa pessoa.</p>
        <a @click="ligarOrganizacao">+ Ligar uma organização</a>
      </div>
    </div>
    <div class="bodyBox e-input-modern label-size-2 size1" v-else>
      <div class="infoCont f-center">
        <p class="txtLeft">Organização</p>
        <div class="txtRight" style="flex: 1">
          <autocomplete :search="searchOrganizacao" v-model="fakeOrganization" icon="building"/>
        </div>
      </div>
      <div class="infoCont f-center">
        <p class="txtLeft">&nbsp;</p>
        <div class="txtRight">
          <div>
            <u-btn @click="edit = false" label="Cancelar" no-caps
                   class="btnContato btnForm btnWhite m-r-xs m-l-none"/>
            <u-btn @click="salvarOrganizacao" :loading="loading" type="submit" label="Salvar configurações" no-caps
                   class="btnContato btnForm no-border"/>
          </div>
        </div>
      </div>
    </div>
  </pessoa-box>
</template>

<script>
import PessoaMixin from './mixin'
import PessoaBox from "@/components/pessoa/components/include/pessoa/Box"
import {UTooltip, UPopover} from "uloc-vue"
import MenuOptionsItem from '@/components/layout/context-menu/context-window-options-item'
import PessoaDetalheField from "@/components/pessoa/components/include/pessoa/DetalheField"
import SlLoading from "@/components/layout/components/Loading"
import {updatePersonOrganization} from "@/domain/pessoa/services"
import Autocomplete from "@/components/layout/components/Autocomplete"
import searchOrganizacao from "@/components/pessoa/helpers/searchOrganizacao"

export default {
  name: "PessoaOrganizacaoDetalhes",
  mixins: [PessoaMixin],
  components: {SlLoading, PessoaDetalheField, PessoaBox, UTooltip, UPopover, MenuOptionsItem, Autocomplete},
  data () {
    return {
      loading: false,
      edit: false,
      fakeOrganization: null
    }
  },
  methods: {
    ligarOrganizacao (v) {
      this.edit = true
      let org = JSON.parse(JSON.stringify(this.pessoa.organization))
      if (org) {
        org.label = org.name
        org.value = org.name
      }
      this.fakeOrganization = org
    },
    salvarOrganizacao () {
      this.loading = true
      let person = JSON.parse(JSON.stringify(this.pessoa))
      person.organization = this.fakeOrganization
      updatePersonOrganization(person)
          .then(response => {
            this.loading = false
            this.edit = false
            this.$emit('updateOrganization', response.data)
            this.pessoa.organization = response.data.organization
            this.$uloc.notify({
              color: 'positive',
              message: `Organização vinculada com sucesso!`,
              position: 'bottom-left',
            })
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    desvincular () {
      this.loading = true
      let person = JSON.parse(JSON.stringify(this.pessoa))
      person.organization = null
      updatePersonOrganization(person)
          .then(response => {
            this.loading = false
            this.$emit('updateOrganization', response.data)
            this.pessoa.organization = response.data.organization
            this.edit = false
            this.$uloc.notify({
              color: 'black',
              message: `Organização desvinculada desta pessoa.`,
              position: 'bottom-left',
            })
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    searchOrganizacao(terms, done) {
      searchOrganizacao(terms)
          .then(response => {
            const extra = {
              label: `Adicionar "${terms}" como nova organização`,
              value: terms,
              isNew: true
            }
            let result = response.data.result.map(p => {
              return {
                ...p,
                label: p.name,
                value: p.name,
                active: null
              }
            })
            result.push(extra)
            done(result)
          }).catch(error => {
        this.alertApiError(error)
      })
    },
  }
}
</script>
