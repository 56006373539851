<template>
  <pessoa-box class="no-border box-actions-concluido box-historico overflow-unset">
    <div class="c-center">
      <h5>Concluído</h5>
    </div>

    <ul class="nav-aba">
      <li v-for="(m, i) in menu"><a @click="changeTab(m)"
                                    :class="{ativo: m.name === active, hasData: hasData(m) || m.name === 'tudo'}">{{
          m.label
        }}<span class="count" v-if="hasData(m)">{{ hasData(m) }}</span></a></li>
    </ul>

    <div class="cont-bodyAll pos-rlt">
      <div class="itemAba ativo">
        <div class="timelineBar"></div>
        <ul class="line-andamento">
          <!--<li class="with-box">
            <i class="box-icon material-icons">attach_file</i>
            ...<br>
            ...<br>
            ...<br>
            ...<br>
          </li>

          <li class="with-box note">
            <i class="box-icon material-icons">description</i>
            <h4><span>Pessoa criada</span></h4>
            <p>Hoje às 05:42 <span>Tiago (Aplicativo web)</span></p>
          </li>
          <li class="no-icon">
            <h4>Negócio criado em: <span>Negócio Maria</span> <small>R$ 0</small></h4>
            <p>Hoje às 05:42 <span>Tiago</span></p>
          </li>-->

          <component
              v-for="(h, index) in historico"
              :key="index"
              :h="h"
              v-bind:is="getTab(h)"
              @remove="remove"
          />
          <div v-if="loading">
            <u-fake-loader width="80%"/>
            <u-fake-loader width="20%"/>
          </div>

          <li v-if="!loading && (active === 'tudo' || active === 'alteracoes')" class="no-icon flow-info">
            <h4><span>Pessoa criada</span></h4>
            <p class="firstLetterUppercase">{{ pessoa.createdAt.date|formatDateDistance }} <span class="withSeparator">{{
                pessoa.createdByName || pessoa.createdByUser
              }} <!--(Aplicativo web)--></span></p>
          </li><!---->
        </ul>
      </div>
    </div>
  </pessoa-box>
</template>

<script>
import PessoaMixin from './mixin'
import PessoaBox from "@/components/pessoa/components/include/pessoa/Box"
import {historico, showNotaLogByReferId} from "@/domain/pessoa/services"
import FlowSimple from '../historico/Simple'
import FlowModify from '../historico/Modify'
import Nota from '../historico/Nota'
import Call from '../historico/Call'
import File from '../historico/File'
import Vue from "vue"
import HistoryState from "@/domain/pessoa/helpers/HistoryState"
import {UFakeLoader} from "uloc-vue"

Vue.component('simple', FlowSimple)
Vue.component('modify', FlowModify)
Vue.component('note', Nota)
Vue.component('call', Call)
Vue.component('file', File)

export default {
  name: "PessoaHistorico",
  mixins: [PessoaMixin],
  components: {
    PessoaBox, UFakeLoader
  },
  data() {
    return {
      loading: true,
      active: 'tudo',
      historico: null,
      counts: null
    }
  },
  mounted() {
    this.load(this.active)
    HistoryState.listen = this.loadHistoryByRefer
  },
  beforeDestroy() {
    HistoryState.listen = null
  },
  computed: {
    menu() {
      return [
        {
          label: 'Tudo',
          name: 'tudo'
        },
        /*{
          label: 'Tarefas',
          name: 'atividades'
        },*/
        {
          label: 'Observações',
          name: 'observacoes'
        },
        {
          label: 'Contatos',
          name: 'comunicacao'
        },
        {
          label: 'Arquivos',
          name: 'arquivos'
        },
        {
          label: 'Negócios',
          name: 'negocios'
        },
        {
          label: 'Financeiro',
          name: 'financeiro'
        },
        {
          label: 'Alterações',
          name: 'alteracoes'
        }
      ]
    }
  },
  watch: {
    active(v) {
      this.load(v)
    }
  },
  methods: {
    changeTab(m) {
      if (!this.hasData(m) && m.name !== 'tudo') return
      this.active = m.name
    },
    load(t) {
      this.loading = true
      this.historico = null
      historico(this.pessoa, t)
          .then(({data}) => {
            console.log(data)
            this.historico = data.historico
            this.counts = data.counts
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    getTab(h) {
      let object = h.action.split('.')
      object = object[0]
      switch (object) {
        case 'note':
        case 'comunication':
          return 'note'
        case 'modify':
          return 'modify'
        case 'file':
          return 'file'
        case 'call':
          return 'call'
        default:
          return 'simple'
      }
    },
    loadHistoryByRefer(referId) {
      console.log('Loading history by refer id ', referId)
      showNotaLogByReferId(this.pessoa, referId)
          .then(({data}) => {
            this.historico.unshift(data)
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    hasData(m) {
      return this.counts && typeof this.counts[m.name] !== 'undefined' && Number(this.counts[m.name]) > 0 ? Number(this.counts[m.name]) : null
    },
    remove(h) {
      this.historico.splice(this.historico.indexOf(this.historico.find(hi => hi.id === h.id)), 1)
    }
  }
}
</script>
