<template>
  <pessoa-box class="c-fullHeader">
    <div class="full-bg">
      <div class="c-pessoa">
        <div class="cont-avatar">
          <img v-if="pessoa.photo" :src="pessoa.photo">
          <div v-else class="img-fake">{{ firstLettersName(pessoa.name) }}</div>
        </div>
        <div class="show-pessoa">
          <strong>{{ pessoa.name }}</strong>
          <pessoa-tags :pessoa="pessoa"/><!--<i class="fas fa-tag"></i>-->
        </div>
      </div><!-- Pessoa Ativa-->

      <div class="right">
        <div class="c-user">
          <pessoa-owner ref="ownerComponent" :owner="owner" :pessoa="pessoa"/>
        </div><!-- Show User -->

        <div class="c-actions">
          <u-btn disable no-caps class="btnContato no-border-radius-right">Adicionar novo negócio</u-btn>
          <u-btn icon="sort-down" icon-type="fa" no-caps class="simple-btn p-btn-down">
            <u-popover class="window-context-menu menu-profile min" anchor="bottom left" self="top left" :offset="[0, 5]">
              <div>
                <ul>
                  <menu-options-item  label="Adicionar novo negócio" close  />
                  <menu-options-item @click="simpleNewPerson" label="Adicionar nova pessoa" close  />
                </ul>
              </div>
            </u-popover>
          </u-btn>
          <u-btn icon="ellipsis-h" icon-type="fa" class="btn-action">
            <u-popover class="window-context-menu menu-profile min" anchor="bottom left" self="top left" :offset="[0, 5]">
              <div>
                <ul>
                      <menu-options-item label="Editar cadastro" @click="$emit('editar')" close  />
<!--                  <menu-options-item label="Excluir" close  />--> <!-- @TODO: Excluir uma pessoa é complexo. Analisar -->
<!--                  <menu-options-item label="Mesclar" close  />--> <!-- @TODO -->
                  <menu-options-item v-if="pessoa.owner" @click="$refs.ownerComponent.desvincular()" label="Desvincular proprietário" close  />
                </ul>
                <div class="diviser"></div>
                <ul>
                  <menu-options-item disabled label="Gerenciar seções da barra lateral" close  /> <!-- @TODO -->
                </ul>
              </div>
            </u-popover>
          </u-btn>
        </div><!-- END Actions -->
      </div>
    </div>
  </pessoa-box><!-- END Full Header -->
</template>

<script>
import PessoaMixin from './mixin'
import PessoaBox from "@/components/pessoa/components/include/pessoa/Box"
import PessoaTags from "@/components/pessoa/components/include/pessoa/Tags"
import {getOwner} from "@/domain/pessoa/helpers/Owners"
import PessoaOwner from "@/components/pessoa/components/include/pessoa/Owner"
import {UPopover} from "uloc-vue"
import MenuOptionsItem from '@/components/layout/context-menu/context-window-options-item'
import simpleNewPerson from "@/components/pessoa/windows/simpleNewPerson"

export default {
  name: "PessoaHeader",
  components: {PessoaOwner, PessoaTags, PessoaBox, MenuOptionsItem, UPopover},
  mixins: [PessoaMixin],
  computed: {
    owner() {
      return getOwner(this.pessoa)
    }
  },
  methods: {
    simpleNewPerson: simpleNewPerson
  }
}
</script>
