<template>
  <div class="header-actions">
    <ul class="nav-aba">
      <li @click="openApp(app)" v-for="app in apps" :key="app.name"><a :class="{ativo: active === app.name}"><i :class="app.icon"></i> {{ app.label }}</a></li>

<!--      <span><i class="fas fa-times"></i></span>-->
    </ul>
  </div>
</template>

<script>
import {pessoaApps} from "@/components/pessoa/components/include/pessoa/apps/appList"
export default {
  name: "PessoaAbaHeader",
  props: ['active'],
  computed: {
    apps () {
      return pessoaApps
    }
  },
  methods: {
    openApp (app) {
      this.$emit('changeApp', app.name)
    }
  }
}
</script>
